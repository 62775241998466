const state = {
  values: {},
};

const getters = {
  values: state => state.values,
};

const actions = {
  addValueAction: ({ commit }, value) => {
    commit('addValue', value);
  },
};

const mutations = {
  addValue: (state, { id, payload }) => {
    state.values = Object.assign({}, state.values, {
      [id]: payload,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
