const state = {
  values: [],
};

const getters = {
  values: state => state.values,
};

const actions = {
  setValuesAction: ({ commit }, values) => {
    commit('setValues', values);
  },
  addValueAction: ({ commit }, value) => {
    commit('addValue', value);
  },
};

const mutations = {
  setValues: (state, values) => {
    // TODO: refactor (it wants to exclude duplicates)
    const all = [...values, ...state.values];
    const unique = new Set(all.map(v => v.id));
    state.values = [...unique].map(id => all.find(a => a.id === id));
  },
  addValue: (state, value) => {
    state.values = state.values.filter(v => v.id !== value.id).concat(value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
